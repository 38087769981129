<template>
  <vbt-content-box
    :no-content="!estimationsKeys.length"
    :loading-error="loadingError"
    :loading="loading"
    title="Estimations"
  >
    <v-layout
      v-for="(key, i) in estimationsKeys"
      :key="genGUID(key)"
      :class="{ 'mt-8': i > 0 }"
      column
    >
      <div>
        Contract:
        <strong>
          {{ key }}
        </strong>
      </div>

      <v-divider
        style="border-width: 1px; background: #5c5c5c; border-color: #5c5c5c;"
        class="mt-2"
      />

      <v-layout
        v-for="(estimation, j) in stackedEstimations[key]"
        :key="genGUID(estimation)"
        column
      >
        <estimation-item
          :item="estimation"
          :hide-header="j > 0"
        />
      </v-layout>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { genGUID } from '@helpers';

import EstimationItem from './EstimationItem.vue';

const generateStackedEstimations = (items) => {
  const reduced = items.reduce((acc, item) => {
    // Contract may be null from server, or generations used '-'
    const contractKey = item.contract || '-';

    acc[contractKey] = acc[contractKey] || [];

    // eslint-disable-next-line no-unused-expressions
    item?.items.forEach((estimationItem) => {
      estimationItem.selectedRate = (estimationItem.rates || [])
        .find(({ selected }) => selected);

      (estimationItem.rates || []).sort((a, b) => {
        const comparableA = a.accepted + a.selected;
        const comparableB = b.accepted + b.selected;

        if (comparableA > comparableB) {
          return -1;
        }
        if (comparableA < comparableB) {
          return 1;
        }

        return 0;
      });
    });

    acc[contractKey].push(item);

    return acc;
  }, {});

  Object.values(reduced).forEach(values => values.sort(a => (a.selected ? -1 : 0)));

  return reduced;
};

export default {
  name: 'EstimationsByContractType',

  components: {
    EstimationItem,
  },

  props: {
    estimations: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingError: {
      type: [Boolean, Object],
      default: false,
    },
  },

  computed: {
    stackedEstimations() {
      const { estimations } = this;
      const clonedEstimations = JSON.parse(JSON.stringify(estimations));

      return generateStackedEstimations(clonedEstimations);
    },
    estimationsKeys() {
      return Object.keys(this.stackedEstimations);
    },
  },

  methods: {
    genGUID,
  },
};
</script>
