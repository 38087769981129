<template>
  <vbt-dialog
    v-model="dialog"
    hide-submit-btn
    title="Input Message"
    @close="setEstimationInputMessage({})"
  >
    <template #activator>
      <v-btn
        :loading="loading"
        x-small
        color="info"
        @click="getInputMessage"
      >
        Input message
      </v-btn>
    </template>

    <vbt-code
      :value="inputMessageCode"
      max-height="500px"
      readonly
    />
  </vbt-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

export default {
  name: 'EstimationInputMessage',

  data() {
    return {
      dialog: false,
      loading: false,
    };
  },

  computed: {
    ...mapState('estimation', {
      inputMessageCode: ({ estimationInputMessage: obj }) => (
        obj && Object.keys(obj).length ? JSON.stringify(obj, null, '\t') : ''
      ),
    }),
  },

  methods: {
    ...mapMutations('estimation', ['setEstimationInputMessage']),

    ...mapActions('estimation', {
      getInputMessage(dispatch) {
        this.wrapToLoadingFn({
          req: dispatch.bind({}, 'getEstimationInputMessage', { id: this.$route.params.id }),
          loadingFlagName: 'loading',
          onSuccess: () => {
            if (this.inputMessageCode) {
              this.dialog = true;
            } else {
              this.$VBlackerTheme.notification.warning('Empty input message');
            }
          },
        });
      },
    }),

    wrapToLoadingFn,
  },
};
</script>
