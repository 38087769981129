<template>
  <v-layout>
    <div :class="{
      'estimation-left-box': true,
      'selected-item': item.selected,
    }" />
    <v-layout column>
      <vbt-table
        :headers="headers"
        :items="item.items"
        :hide-header="hideHeader"
      >
      <template #orderItems="{ item: { orderItems } }">
          <div
            v-for="(orderItem, j) in orderItems"
            :key="`order-item-${j}`"
          >
            {{ orderItem }}
          </div>
        </template>

        <template #printPrice="{ item: { printPrice, printPriceWithMarkup } }">
          {{ formatNumber(printPrice, 3) }}
          <v-layout>
              <div
                class="text--disabled"
                style="width: 100px;"
              >
                With markup:
              </div>
              {{ formatNumber(printPriceWithMarkup, 3) }}
            </v-layout>
        </template>

        <template #maxTransitTime="{ item: { maxTransitTime } }">
          {{ maxTransitTime > 0 ? maxTransitTime : '-' }}
        </template>

        <template #weight="{ item: { weight } }">
          {{ formatNumber(weight, 3) }}
        </template>

        <template #mailing="{ item: { mailing } }">
          {{ mailing ? 'Yes' : 'No' }}
        </template>

        <template #printProviderId="{ item: { printProviderId } }">
          {{ accountNameById[printProviderId] }}
        </template>

        <template #error="{ item: { error } }">
          {{ estimationErrorsByTypeId[error] }}
        </template>

        <template #selectedRate="{ item: { selectedRate } }">
          <v-layout
            v-if="selectedRate"
            class="py-1"
            column
          >
            <v-layout>
              <div
                class="text--disabled"
                style="width: 140px;"
              >
                Service Name:
              </div>
              {{ selectedRate.serviceName }}
            </v-layout>
            <v-layout>
              <div
                class="text--disabled"
                style="width: 140px;"
              >
                Delivery Date (local):
              </div>
              {{ formatDisplayDate(selectedRate.deliveryDate, { type: 'local' }) }}
            </v-layout>
            <v-layout>
              <div
                class="text--disabled"
                style="width: 140px;"
              >
                Ship Cost:
              </div>
              {{ selectedRate.shipCost }}
            </v-layout>
            <v-layout>
              <div
                class="text--disabled"
                style="width: 140px;"
              >
                With markup:
              </div>
              {{ selectedRate.shipCostWithMarkup }}
            </v-layout>
          </v-layout>
          <span v-else>
            -
          </span>
        </template>

        <template #rates="{ item }">
          <v-btn
            :disabled="!(item.rates || []).length"
            icon
            color="info"
            @click="showRates(item.rates || [])"
          >
            <v-icon>
              remove_red_eye
            </v-icon>
          </v-btn>
        </template>
      </vbt-table>

      <v-layout
        style="font-size: 12px; padding-right: 12%;"
        justify-space-between
        class="mt-1 pl-4"
      >
        <span class="error--text">
          {{ (item.errors || []).map((e) => estimationErrorsByTypeId[e]).join('; ') }}
        </span>

        <span v-if="item.shipExpensivePercent">
          <strong class="mr-1 warning--text">
            >
          </strong>
          <span class="warning--text">
            {{ `${item.shipExpensivePercent || 0}%` }};
          </span>
        </span>
      </v-layout>

      <v-layout align-center>
        <v-divider />

        <span
          style="font-size: 12px; font-weight: lighter; opacity: 0.5;"
          class="mx-2 font-italic"
        >
          end of estimation
        </span>

        <v-divider />
      </v-layout>
    </v-layout>

    <item-rates
      v-model="ratesDialog"
      :rates="modalRates"
    />
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatNumber, formatDisplayDate } from '@helpers';

import ItemRates from './ItemRates.vue';

const headers = Object.freeze([
  { text: 'Order Items', value: 'orderItems', width: '4%' },
  { text: 'Print Price', value: 'printPrice', width: '12%' },
  { text: 'Weight', value: 'weight', width: '4%' },
  { text: 'MTT', value: 'maxTransitTime', width: '4%' },
  { text: 'Timezone Offset', value: 'timezoneOffset', width: '6%' },
  { text: 'Mailing', value: 'mailing', width: '4%' },
  { text: 'Print Provider', value: 'printProviderId', width: '15%' },
  { text: 'Error', value: 'error', width: '18%' },
  { text: 'Ship Price', value: 'selectedRate', width: '25%' },
  { text: 'Rates', value: 'rates', width: '5%' },
]);

export default {
  name: 'EstimationItem',

  components: {
    ItemRates,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers,

      modalRates: [],
      ratesDialog: false,
    };
  },

  computed: {
    ...mapGetters('accounts', ['accountNameById']),

    ...mapGetters('enums', ['estimationErrorsByTypeId']),
  },

  methods: {
    showRates(rates) {
      this.modalRates = rates;
      this.ratesDialog = true;
    },

    formatNumber,
    formatDisplayDate,
  },
};
</script>

<style lang="css" scoped>
.estimation-left-box {
  width: 25px;
  min-height: 100%;
  margin-bottom: 10px;
}

.estimation-left-box.selected-item {
  position: relative;
  background-color: #4caf50;
  border-bottom-left-radius: 5px;
}

.estimation-left-box.selected-item:after {
  content: "Selected";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 700;
  transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
}
</style>
