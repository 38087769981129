<template>
  <vbt-content-box
    :loading-error="loadingError"
    :loading="loading"
    title="Information"
  >
    <template #toolbar>
      <estimation-input-message
        :loading-error="loadingError"
        :loading="loading"
      />
    </template>

    <v-layout>
      <v-flex sm3>
        <v-layout class="my-2">
          <strong class="mr-1">
            Invoice:
          </strong>

          <router-link
            v-if="estimation.invoice"
            :to="{
              name: 'estimations',
              query: {
                pageNum: '1',
                searchId: estimation.invoice
              },
            }"
            class="blue--text darken-1"
          >
            {{ estimation.invoice }}
          </router-link>
          <span v-else>
            -
          </span>
        </v-layout>

        <v-layout class="my-2">
          <strong class="mr-1">
            Sender:
          </strong>
          {{ estimation.sender }}
        </v-layout>

        <v-layout class="my-2">
          <strong class="mr-1">
            Errors:
          </strong>

          {{ (estimation.errors || []).join('; ') || '-' }}
        </v-layout>

        <v-layout class="my-2">
          <strong class="mr-1">
            Search IDs:
          </strong>

          <ir-estimation-search-ids :search-ids="estimation.searchIds || []" />
        </v-layout>
      </v-flex>

      <v-flex sm4>
        <v-layout class="my-2">
          <strong class="mr-1">
            Created Date:
          </strong>
          {{ estimation.created | formatDate('local', '-') }}
        </v-layout>

        <v-layout class="my-2">
          <strong class="mr-1">
            Expire Date:
          </strong>
          {{ estimation.expire | formatDate('local', '-') }}
        </v-layout>

        <v-layout class="my-2">
          <strong class="mr-1">
            Estimation Time:
          </strong>
          {{ estimation.estimationTime || '-' }}
        </v-layout>
      </v-flex>

      <v-flex
        v-if="estimation.orderId"
        sm3
      >
        <v-layout class="my-2">
          <strong>
            Order ID:
          </strong>

          <router-link
            :to="{
              name: 'order',
              params: { id: estimation.orderId },
            }"
            class="blue--text darken-1"
          >
            {{ estimation.orderId }}
          </router-link>
        </v-layout>

        <v-layout class="my-2">
          <strong class="mr-1">
            Shipping Fee:
          </strong>
          {{ estimation.shippingFee }}
        </v-layout>

        <v-layout class="my-2">
          <strong class="mr-1">
            Shipping Expensive Percent:
          </strong>
          {{ estimation.shipExpensivePercent ? `${estimation.shipExpensivePercent}%` : '-' }}
        </v-layout>

        <v-layout class="my-2">
          <strong class="mr-1">
            Requested delivery date (local):
          </strong>
          {{ formatDisplayDate(estimation.deliveryDate, { type: 'utc' }) }}
        </v-layout>

        <v-layout class="my-2">
          <strong class="mr-1">
            Contract:
          </strong>
          {{ estimation.contract }}
        </v-layout>
      </v-flex>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { formatDisplayDate } from '@helpers';
import EstimationInputMessage from './EstimationInputMessage.vue';
import IrEstimationSearchIds from './IrEstimationSearchIds.vue';

export default {
  name: 'EstimationMainInfo',

  components: {
    EstimationInputMessage,
    IrEstimationSearchIds,
  },

  methods: {
    formatDisplayDate,
  },

  props: {
    estimation: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingError: {
      type: [Boolean, Object],
      default: false,
    },
  },
};
</script>
