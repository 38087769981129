<template>
  <vbt-dialog
    v-model="dialog"
    title="Search IDs"
    hide-submit-btn
    width="400px"
  >
    <template #activator="{ on }">
      <v-btn
        :disabled="!searchIds.length"
        icon
        small
        color="info"
        @click="dialog = true"
      >
        <v-icon>
          remove_red_eye
        </v-icon>
      </v-btn>
    </template>
    <strong>
      <div
        v-for="(s, i) in searchIds"
        :key="i"
      >
        {{ s }}
      </div>
    </strong>
  </vbt-dialog>
</template>

<script>
export default {
  name: 'IrEstimationSearchIds',

  props: {
    searchIds: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
    };
  },
};
</script>
