<template>
  <v-layout column>
    <estimation-main-info
      :loading-error="loadingError"
      :estimation="estimation"
      :loading="isLoading"
    />

    <estimations-by-contract-type
      :loading-error="loadingError"
      :estimations="estimation.estimations || []"
      :loading="isLoading"
    />
  </v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import EstimationMainInfo from '../_components/EstimationMainInfo.vue';
import EstimationsByContractType from '../_components/EstimationsByContractType';

const dataLoadingOptions = {
  getterName: 'getEstimation',
  parameterize: true,
  searchable: false,
  paged: false,
};

export default {
  name: 'Estimation',

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  components: {
    EstimationMainInfo,
    EstimationsByContractType,
  },

  computed: {
    ...mapState('estimation', ['estimation']),
  },

  created() {
    this.getAccounts();
  },

  methods: {
    ...mapActions('accounts', ['getAccounts']),
    ...mapActions('estimation', ['getEstimation']),
  },
};
</script>
